
<script>
import store from "@/store";
import { mapActions, mapState } from "vuex";
import ProfileStoreModule from "@/pages/profile/ProfileStoreModule";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      authenticated: store.state.auth.authenticated,
      user: store.state.auth.user,
      loadingData: false,
      url: "/profile/",
      totalUserOnline: store.state.auth.onlineUser,
      provincia: store.state.auth.provincia,
      userId: store.state.auth.user.id,
      profile_pic: store.state.auth.user.profile_pic,
      userData: {},
      notifications: [],
      ProfilePath: "https://votes.vm7falaki.com/starter/assets/upload_profile/",
      // ProfilePath: "http://127.0.0.1:8000/assets/upload_profile/",
      // profi: store.state.auth.imgState
    };
  },
  computed: {
    ...mapState("auth", {
      img: "imgState",
    }),
  },

  watch: {
    async img(changed) {
      if (changed) {
        const resp = await ProfileStoreModule.user();
        this.profile_pic = store.state.auth.user.profile_pic;
        store.dispatch("auth/updateimgstate", false);
      }
    },
  },

  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      await axios
        .get(url + "logout", { headers: authHeader() })
        .then((response) => {
          if (response.data.status) {
            this.signOut();
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
          console.log(response);
        });
    },

    showpost(post_id) {
      try {
        router.push({
          name: "comments",
          params: {
            id: post_id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getNotifications() {
      try {
        this.loadingData = true;
        await axios
          .get(url + "mynotifications", { headers: authHeader() })
          .then((response) => {
            console.log("notifications", response.data);
            this.notifications = response.data.data.data;
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
  },

  created() {
    if (store.state.auth.authenticated) {
      this.profile_pic =
        store.state.auth.user.profile_pic == null
          ? "https://bitwise.bitwise.co.mz/assets/images/avatar4.png"
          : "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/" +
            store.state.auth.user.profile_pic;
    }

    // this.getNotifications();
  },
};
</script>

<template>
  <div class="topbar">
    <nav class="navbar navbar-expand-lg navbar-light bg-warning">
      <div class="container">
        <a class="navbar-brand" href="/" style="margin-left: -1em">
          <span
            class="d-block d-sm-none d-lg-block d-md-block rounded-circle text-white"
            style="font-weight: bold"
          >
            <img
              src="../../public/assets/images/Logo_Branco.jpeg"
              height="55"
              alt=""
            />
          </span>
        </a>

        <!-- <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- <ul class="list-inline mb-0">
            <li class="d-none d-md-inline-block">
              <form class="form-inline ml-0 ml-md-3 app-search">
                <div class="input-group input-group-sm">
                  <div class="form-group mb-0">
                    <input
                      class="form-control form-control-navbar"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                    <button type="submit">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
          </ul> -->
          <ul
            class="ml-auto nav navbar-right list-inline mb-0"
            style="margin-right: -2em"
          >
            <li
              v-if="authenticated == true"
              class="dropdown notification-list list-inline-item"
            >
              <router-link
                class="nav-link dropdown-toggle arrow-none waves-effect"
                to="/"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                Home
              </router-link>
            </li>
            <li
              v-if="authenticated == true"
              class="dropdown notification-list list-inline-item"
            >
              <router-link
                class="nav-link dropdown-toggle arrow-none d-none d-sm-none d-lg-block d-md-block"
                to="/addpolling"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                Adicionar votos
              </router-link>
            </li>

            <!-- <li class="dropdown notification-list list-inline-item">
              <a
                v-if="totalUserOnline > 0"
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="fas fa-circle text-success"></i>
                Online: {{ totalUserOnline }}
              </a>
              <a
                v-else
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="fas fa-circle text-danger"></i>
                Online: {{ totalUserOnline }}
              </a>
            </li> -->

            <!-- <li class="dropdown notification-list list-inline-item">
              <a
                class="nav-link dropdown-toggle arrow-none waves-effect"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                {{ provincia }}
              </a>
            </li> -->
            <!-- 
            <li class="dropdown notification-list list-inline-item">
              <a
                class="nav-link dropdown-toggle arrow-none waves-effect"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="mdi mdi-bell-outline noti-icon"></i>
                <span class="badge badge-pill badge-danger noti-icon-badge">{{
                  notifications.length || 0
                }}</span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg px-1"
              >
                <h6 class="dropdown-item-text">Notificações</h6>
                <div
                  class="slimscroll notification-item-list"
                  v-if="notifications.length > 0"
                >
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item"
                    v-for="(n, index) in notifications"
                    :key="index"
                  >
                    <div class="notify-icon bg-danger">
                      <i class="mdi mdi-message-text-outline"></i>
                    </div>
                    <p class="notify-details" @click="showpost(n.post_id)">
                      <b>Nova notificacao recebida</b><br /><router-link
                        class="text-muted"
                        to="/"
                        >{{ n.description }}</router-link
                      >
                    </p>
                  </a>
                </div>
                <a
                  v-if="notifications.length > 0"
                  href="javascript:void(0);"
                  class="dropdown-item text-center notify-all text-primary"
                >
                  View all <i class="fi-arrow-right"></i>
                </a>
              </div>
            </li> -->

            <li class="dropdown notification-list list-inline-item">
              <router-link
                class="nav-link dropdown-toggle arrow-none waves-effect"
                :to="url + user.id"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
              </router-link>
            </li>
            <li
              v-if="authenticated == true"
              class="dropdown notification-list list-inline-item"
            >
              <div class="dropdown notification-list nav-pro-img">
                <a
                  class="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    v-if="profile_pic == null"
                    src="https://bitwise.co.mz/assets/images/user-4.jpg"
                    alt="user"
                    class="rounded-circle"
                  />
                  <!-- <img v-else :src="ProfilePath+profile_pic"  class="rounded-circle" alt=""> -->
                  <img
                    v-else
                    :src="profile_pic"
                    alt="user"
                    class="rounded-circle"
                  />
                </a>
                <div
                  v-if="authenticated == true"
                  class="dropdown-menu dropdown-menu-right profile-dropdown"
                >
                  <router-link
                    class="dropdown-item text-white"
                    :to="{ name: 'profile', params: { id: userId } }"
                    >Perfil</router-link
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    href="javascript:void(0)"
                    @click="logout"
                    ><i class="mdi mdi-power text-danger"></i> Sair</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>


<style scoped>
@media screen and (max-width: 680px) {
  #mobileTitle {
    visibility: hidden;

    display: none;
  }
}
</style>
