<template>
  <div class="row">
    <!-- accepted payments column -->

    <div class="col-md-9 col-sm-8">
      <div class="card col-lg-12 col-sm-12">
        <h1
          class="text-center mb-5"
          style="font-size: 18px; font-family: sans-serif"
        >
          Buscar resultados por provincia
        </h1>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o Provincia*</label>
              <select
                class="form-control"
                v-model="userData.province_id"
                @change="ChangeProvince($event)"
              >
                <option value="" disabled>Selecione o Provincia</option>
                <option
                  :value="provinces.id"
                  v-for="provinces in provincesData"
                  :key="provinces.id"
                >
                  {{ provinces.name }}
                </option>
              </select>
            </div>
            <p v-if="provinceError" style="color: red">
              {{ provinceError }}
            </p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o Distrito*</label>
              <select
                :disabled="loadDistricts"
                class="form-control"
                @change="getPollignStations($event)"
                v-model="userData.district_id"
              >
                <option value="0">Selecione o Distrito</option>
                <option
                  :value="district.id"
                  v-for="district in districtData"
                  :key="district.id"
                >
                  {{ district.name }}
                </option>
              </select>
            </div>
            <p v-if="districtError" style="color: red">
              {{ districtError }}
            </p>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o Posto*</label>
              <select
                :disabled="loadDistricts"
                class="form-control"
                @change="getTablePolling($event)"
                v-model="userData.pollingStation_id"
              >
                <option value="0">Selecione o posto</option>
                <option :value="p.id" v-for="p in pollingData" :key="p.id">
                  {{ p.description }}
                </option>
              </select>
            </div>
            <p v-if="districtError" style="color: red">
              {{ districtError }}
            </p>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione a mesa*</label>
              <select
                :disabled="loadDistricts"
                class="form-control"
                @change="getActasByTablePolling($event)"
                v-model="userData.tablePolling_id"
              >
                <option value="0" selected>Selecione a mesa</option>
                <option :value="t.id" v-for="t in tablePolling" :key="t.id">
                  {{ t.description }}
                </option>
              </select>
            </div>
            <p v-if="districtError" style="color: red">
              {{ districtError }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-sm-8" v-if="results.length > 0">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Resultados na provincia de {{ province ? province : "------" }}
            </h1>
          </div>
          <form @submit.prevent="submitData">
            <div class="row" v-for="(r, index) in results" :key="index">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.party"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.total"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.percentage"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-sm-8">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Resultados da Destruição dos Acentos
            </h1>
          </div>
          <form @submit.prevent="submitData">
            <div
              class="row"
              v-for="(r, index) in seatsDistribuitions"
              :key="index"
            >
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label" v-if="index == 0">Partidos*</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.party"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <label class="form-label" v-if="index == 0">Votos*</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.votes"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label" v-if="index == 0">Acentos*</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.seats"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-sm-8">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Resultados em todo pais
            </h1>
          </div>
          <form @submit.prevent="submitData">
            <div class="row" v-for="(r, index) in resultsCoutry" :key="index">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <label class="form-label" v-if="index == 0">Partidos*</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.party"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <label class="form-label" v-if="index == 0"
                    >Total de Votos*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.total"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <!-- <label class="form-label">Posto de votação*</label> -->
                  <label class="form-label" v-if="index == 0"
                    >Total de Votos em Percentagem*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="r.percentage"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Province -->

    <div class="col-md-9 col-sm-8">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover w-100">
              <thead>
                <tr>
                  <th>Província</th>
                  <th>Partido</th>
                  <th>Total de Votos</th>
                  <th>Assentos</th>
                  <th>Porcentagem</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(p, id) in province_data" :key="id">
                  <tr v-for="(party, index) in p.partidos" :key="index">
                    <td :rowspan="p.partidos.length" v-if="index === 0">
                      {{ p.provincia }}
                    </td>
                    <td>{{ party.party }}</td>
                    <td>{{ party.total }}</td>
                    <td>{{ party.seats }}</td>
                    <td>{{ party.percentage }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-sm-8">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Resultados em todo pais
            </h1>
          </div>
          <div class="col-lg-12">
            <Bar
              v-if="loaded"
              id="my-chart-id"
              :options="chartOptions"
              :data="chartData"
              style="width: 100%; height: 200px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-sm-8" v-if="actas.length > 0">
      <div class="card col-lg-12 col-sm-12">
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Actas
            </h1>
          </div>
          <div class="row">
            <div
              class="us col-lg-2 mb-1"
              v-for="(a, index) in actas"
              :key="index"
            >
              <a
                :href="
                  'https://votes.vm7falaki.com/starter/assets/upload_img/' +
                  a.img
                "
                download="hello"
                target="_blank"
              >
                <img
                  :src="
                    'https://votes.vm7falaki.com/starter/assets/upload_img/' +
                    a.img
                  "
                  alt="actas"
                  style="width: 100%; object-fit: cover"
                  height="50"
                  class="img-bordered-sm"
                />
              </a>
            </div>
            <div class="col-lg-12 d-flex justify-content-center">
              <nav
                aria-label="Page navigation example d-flex justify-content-center bg-warning"
              >
                <ul class="pagination float-right">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      @click.prevent="listMoreData(currentPage - 1)"
                      href="javascript:void(0)"
                      class="page-link"
                      >Anterior</a
                    >
                  </li>
                  <li
                    class="page-item"
                    v-for="pageNumber in totalPages"
                    :key="pageNumber"
                    :class="{ active: pageNumber === currentPage }"
                  >
                    <a
                      href="javascript:void(0)"
                      class="page-link"
                      @click.prevent="listMoreData(pageNumber)"
                      >{{ pageNumber }}</a
                    >
                  </li>
                  <li class="page-item">
                    <a
                      href="javascript:void(0)"
                      class="page-link"
                      @click.prevent="listMoreData(currentPage + 1)"
                      >Próxima</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :loadingData="loading" />

    <!-- /.col -->
  </div>
</template>
<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import Results from "./Results";
import Loading from "@/components/Loading.vue";

import { Bar, Doughnut, Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);

const loading = ref(false);
const loaded = ref(false);

const chartData = ref(null);
const provincesData = ref([]);
const districtData = ref([]);
const seatsDistribuitions = ref([]);
const province_data = ref([]);
const userData = ref({
  province_id: "",
  district_id: "",
  pollingStation_id: "",
  tablePolling_id: "",
});
const url = ref(process.env.VUE_APP_API_URL);
const results = ref([]);
const pollingData = ref([]);
const tablePolling = ref([]);
const actas = ref([]);
const resultsCoutry = ref([]);
const province = ref("");
const currentPage = ref(1);
const totalPages = ref(0);
const active = ref("active");
const isProvince = ref(false);
const isDistrict = ref(false);
const isPostPolling = ref(false);
const isTablePolling = ref(false);

const getProvinces = async () => {
  loading.value = true;

  await axios
    .get(url.value + "provinces")
    .then((response) => {
      provincesData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};
const ChangeProvince = async (evt) => {
  try {
    loading.value = true;
    isProvince.value = true;
    isDistrict.value = false;
    isPostPolling.value = false;
    isTablePolling.value = false;
    // let data = provincesData.value.find((item) => (item.id = evt.target.value));
    // province.value = data.name;
    const response = await Results.getResutsByProvince(evt.target.value);
    results.value = response;
    const resp = await Results.getActas(evt.target.value);
    actas.value = resp.data;
    totalPages.value = resp.last_page;
    currentPage.value = resp.current_page;

    getDistricts(evt);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const allSeatDistribuition = async () => {
  try {
    loading.value = true;

    const response = await Results.getResutsByCOfEachProvince();
    // results.value = response;
    province_data.value = response;
    console.log("dat", response);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const getDistricts = async (evt) => {
  loading.value = true;

  await axios
    .get(url.value + "district/" + evt.target.value)
    .then((response) => {
      console.log(response.data);
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const getPollignStations = async (evt) => {
  loading.value = true;

  isProvince.value = false;
  isDistrict.value = true;
  isPostPolling.value = false;
  isTablePolling.value = false;

  await axios
    .get(url.value + "pollingStation/" + evt.target.value)
    .then((response) => {
      pollingData.value = response.data;
      getActasByDistrict(evt);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const getActasByDistrict = async (evt) => {
  try {
    loading.value = true;

    let resp = await Results.getActasByDistrict({
      page: 1,
      district_id: evt.target.value,
    });
    actas.value = resp.data;
    totalPages.value = resp.last_page;
    currentPage.value = resp.current_page;
    console.log(resp.data);
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getActasFromPollingStation = async (evt) => {
  loading.value = true;

  await axios
    .get(
      url.value +
        `getActasFromPollingStation?page=1&pollingStation_id=${evt.target.value}`
    )
    .then((resp) => {
      actas.value = resp.data.data;
      totalPages.value = resp.data.last_page;
      currentPage.value = resp.data.current_page;
      console.log("Pollstation", resp.data.data);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const getTablePolling = async (evt) => {
  loading.value = true;

  isProvince.value = false;
  isDistrict.value = false;
  isPostPolling.value = true;
  isTablePolling.value = false;

  await axios
    .get(url.value + "tablePollingStation/" + evt.target.value)
    .then((response) => {
      tablePolling.value = response.data;

      getActasFromPollingStation(evt);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const getActasByTablePolling = async (evt) => {
  loading.value = true;

  isProvince.value = false;
  isDistrict.value = false;
  isPostPolling.value = false;
  isTablePolling.value = true;
  await axios
    .get(
      url.value +
        `getActasByTablePolling?page=1&tablePolling_id=${evt.target.value}`
    )
    .then((response) => {
      console.log("mesa", response);
      actas.value = response.data.data;
      totalPages.value = response.data.last_page;
      currentPage.value = response.data.current_page;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const listMoreData = async (page = 1) => {
  try {
    loading.value = true;
    let resp;
    if (isProvince.value == true) {
      resp = await Results.getActasByProvince({
        page: page,
        province_id: userData.value.province_id,
      });
    } else if (isDistrict.value == true) {
      resp = await Results.getActasByDistrict({
        page: page,
        district_id: userData.value.district_id,
      });
    } else if (isPostPolling.value == true) {
      resp = await Results.getActasFromPollingStation({
        page: page,
        pollingStation_id: userData.value.pollingStation_id,
      });
    } else {
      resp = await Results.getActasByTablePolling({
        page: page,
        tablePolling_id: userData.value.tablePolling_id,
      });
    }

    actas.value = resp.data;
    console.log(resp.data);
    totalPages.value = resp.last_page;
    // per_page.value = resp.data.meta.per_page;
    currentPage.value = resp.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const getResultsByCountry = async () => {
  loading.value = true;
  loaded.value = false;
  await axios
    .get(url.value + "getResutsByCountry")
    .then((response) => {
      resultsCoutry.value = response.data;
      // Lógica para determinar as cores com base nos dados
      const colors = response.data.map((item) => {
        if (item.total > 1000) return "#dc3545";
        else if (item.total > 500) return "#dc354e";
        else if (item.total > 100) return "#dc9845";
        else return "#d22545";
      });
      chartData.value = {
        labels: ["MDM", "FRELIMO", "RENAMO", "PODEMOS"],
        datasets: [
          {
            label: "Estatística  por partido",
            data: [
              response.data[0].total,
              response.data[1].total,
              response.data[2].total,
              response.data[3].total,
            ],
            backgroundColor: colors,
          },
        ],
      };
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;

      loaded.value = true;
    });
};

const getResultsSeatsDistribuitions = async () => {
  loading.value = true;
  loaded.value = false;
  await axios
    .get(url.value + "getSeatDistribuitions")
    .then((response) => {
      // resultsCoutry.value = response.data;
      // Lógica para determinar as cores com base nos dados
      seatsDistribuitions.value = response.data.data;
      console.log("seats", response.data);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;

      loaded.value = true;
    });
};

async function getResultsInGraph() {
  loaded.value = false;

  try {
    const resp = await Results.getResutsByCountry();
    // const respRealState =
    //   await statisticStoreModule.actions.getTotalRealestate();

    chartData.value = {
      labels: ["Homens", "Mulheres"],
      datasets: [
        {
          label: "Estatística  por género",
          data: [resp.party, resp.total],
          backgroundColor: ["#dc3545", "#dc354e", "#dc9845", "#d22545"],
        },
      ],
    };

    console.log(resp.data);

    loaded.value = true;
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getProvinces();
  getResultsByCountry();
  getResultsSeatsDistribuitions();
  allSeatDistribuition();
  // getResultsInGraph()
});
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
</style>